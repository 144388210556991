<template>
  <form @submit.prevent="attachExistingFile">
    <div class="margin-b-standard">
      <RightSidebarTitle :title="'Search your library of attachments:'" />
      <input
        @keyup="clearExistingFileSelection"
        id="existing-file-filter"
        ref="existingFileFilterInput"
        v-model="existingFileFilter"
        data-testing="attach-existing-file-name"
      />
    </div>
    <ul class="available-files margin-b-standard">
      <li v-for="file in filesData" :value="file.id" :key="file.id">
        <label data-testing="existing-file-name">
          <input
            :data-testing="`existing-file-name-${file.id}`"
            type="radio"
            :value="file.id"
            v-model="existingFileId"
          />
          {{ file.name }}
        </label>
      </li>
    </ul>

    <button
      data-testing="attach-existing-file-btn"
      :disabled="!existingFileId"
      class="btn-1 full-width submit-btn"
    >
      <span v-if="uiFilesStore.shouldBeSetAsMediaContent"
        >Set as Media Content</span
      >
      <span v-else>Attach content</span>
    </button>
  </form>
</template>
<script lang="ts" setup>
import { LibraryFile } from "@/models/files.model";
import { SectionFile } from "@/models/product-section.model";
import { computed, onMounted, ref } from "vue";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useFilesStore } from "@/stores/files.store";
import { useFileManager } from "@/composables/files-manager";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";

const filesStore = useFilesStore();
const uiFilesStore = useUIFilesStore();
const { linkFileToProduct, linkFileToSection } = useFileManager();

const existingFileFilter = ref<string>("");
const existingFileId = ref<number | undefined>(undefined);

const isFileInSection = (file: LibraryFile): boolean => {
  if (!uiFilesStore.sectionToAttachFile) {
    return false;
  }
  return uiFilesStore.sectionToAttachFile.enabled_files.some(
    (sectionFile: SectionFile): boolean => {
      return sectionFile.id === file.id;
    }
  );
};
const doesFileNameMatchFilter = (file: LibraryFile): boolean => {
  return file.name
    .toLowerCase()
    .includes(existingFileFilter.value.toLowerCase());
};
const filesData = computed<LibraryFile[]>(() => {
  let files = filesStore.files ?? [];
  files = files.filter((file: LibraryFile) => {
    return !isFileInSection(file);
  });
  files = files.filter(doesFileNameMatchFilter);
  return files;
});

const attachFileToProduct = (attachedFileId: number): void => {
  if (uiFilesStore.productToAttachFile && uiFilesStore.fileAttachmentType) {
    linkFileToProduct({
      product_id: uiFilesStore.productToAttachFile.id,
      attachment_type: uiFilesStore.fileAttachmentType,
      file_id: attachedFileId
    });
  }
};
const attachFileToSection = (attachedFileId: number): void => {
  if (uiFilesStore.sectionToAttachFile) {
    linkFileToSection({
      productId: uiFilesStore.sectionToAttachFile.product_id,
      section_id: uiFilesStore.sectionToAttachFile.id,
      file_id: attachedFileId,
      is_media_content: uiFilesStore.shouldBeSetAsMediaContent
    });
  }
};
const attachExistingFile = (): void => {
  if (existingFileId.value) {
    if (uiFilesStore.sectionToAttachFile) {
      attachFileToSection(existingFileId.value);
    } else {
      attachFileToProduct(existingFileId.value);
    }
  }

  uiFilesStore.closeAttachFileModal();
};

const clearExistingFileSelection = (): void => {
  existingFileId.value = undefined;
};

const existingFileFilterInput = ref<HTMLElement | null>(null);
onMounted(() => {
  if (existingFileFilterInput.value) {
    existingFileFilterInput.value.focus();
  }
});
</script>
<style lang="scss" scoped>
.available-files {
  padding-left: 0;
  max-height: 9rem; // Should be a multiple of 1.5 so that list items are not cut off half way
  overflow-y: auto;
}
</style>
