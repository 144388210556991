<template>
  <Collapse>
    <template v-slot:title>Curriculum Tags</template>
    <template v-slot:content>
      <b-field v-if="showConceptsSelector" label="Concepts">
        <b-autocomplete
          v-model="typedConcept"
          :data="filteredConceptsByTopics"
          @select="processSelectedConcept"
          placeholder="e.g. Adding fractions"
          group-field="topicName"
          group-options="conceptsNames"
          :confirm-keys="['Enter']"
          keep-first
          open-on-focus
          clearable
          clear-on-select
        >
        </b-autocomplete>
      </b-field>
      <div v-if="!showConceptsSelector">
        {{ message }}
      </div>
      <TagsList
        v-if="showTags"
        :tags="sectionTagsWithNames"
        @removeTag="removeConceptFromSection"
        :can-edit-content="canEditContent"
      />
    </template>
  </Collapse>
</template>

<script lang="ts" setup>
import { computed, PropType, ref } from "vue";
import Collapse from "@/components/right-sidebars/ui/Collapse.vue";
import TagsList from "@/components/right-sidebars/content-sidebar/curriculum/TagsList.vue";
import {
  ConceptJson,
  ConceptsByTopic,
  TopicJson
} from "@/models/curriculum.model";
import { SectionConcepts } from "@/models/product-section.model";
import { ContentStructureSection } from "@/models/content-structure.model";
import { useUIGeneralStore } from "@/stores/ui-general.store";
import { useContentStore } from "@/stores/content.store";

const uiGeneralStore = useUIGeneralStore();
const contentStore = useContentStore();

const props = defineProps({
  section: {
    type: Object as PropType<ContentStructureSection>,
    required: true
  },
  productCurriculumId: {
    type: Number,
    required: true
  },
  isLoading: {
    type: Boolean,
    required: true
  },
  topicsFromXml: {
    type: Array as PropType<TopicJson[]>,
    default: () => []
  },
  conceptsFromXml: {
    type: Array as PropType<ConceptJson[]>,
    default: () => []
  },
  xmlParsedSuccessfully: {
    type: Boolean,
    default: false
  },
  canEditContent: {
    type: Boolean,
    required: true,
    default: false
  }
});

const typedConcept = ref<string>("");

const sectionTagsFromSelectedCurriculum = computed<SectionConcepts[]>(() => {
  if (!props.section.hasOwnProperty("concepts")) {
    return [];
  }
  const conceptsFromXmlGuids = props.conceptsFromXml.map(
    (conceptXml: ConceptJson) => conceptXml.guid
  );
  return props.section.concepts.filter((sectionConcept: SectionConcepts) => {
    return conceptsFromXmlGuids.includes(sectionConcept.concept_guid);
  });
});
const sectionHaveTagsButDoesntHaveTagsFromSelectedCurriculum =
  computed<boolean>(() => {
    return (
      props.section.concepts.length > 0 &&
      sectionTagsFromSelectedCurriculum.value.length === 0
    );
  });
const sectionTagsWithNames = computed<SectionConcepts[]>(() => {
  return sectionTagsFromSelectedCurriculum.value.map(
    (sectionConcept: SectionConcepts) => {
      const conceptName = props.conceptsFromXml.find(
        (conceptFromXml: ConceptJson) => {
          return conceptFromXml.guid === sectionConcept.concept_guid;
        }
      );
      if (conceptName) {
        sectionConcept.name = conceptName.name;
      }
      return sectionConcept;
    }
  );
});
const conceptsByTopicsFromXml = computed<ConceptsByTopic[]>(() => {
  const conceptsByTopics: ConceptsByTopic[] = [];
  props.topicsFromXml.forEach((topic: TopicJson) => {
    const item: ConceptsByTopic = {
      topicName: topic.name,
      topicFullData: topic,
      conceptsNames: [],
      conceptsFullData: []
    };
    props.conceptsFromXml.forEach((concept: ConceptJson) => {
      if (topic.guid === concept.parentGuid) {
        item.conceptsNames.push(concept.name);
        item.conceptsFullData.push(concept);
      }
    });
    conceptsByTopics.push(item);
  });
  return conceptsByTopics;
});
const filteredConceptsByTopics = computed<ConceptsByTopic[]>(() => {
  if (!typedConcept.value.length) {
    return conceptsByTopicsFromXml.value;
  }
  const filteredConceptsByTopics: ConceptsByTopic[] = [];
  conceptsByTopicsFromXml.value.forEach((element: ConceptsByTopic) => {
    const conceptsNames = element.conceptsNames.filter(
      (conceptName: string) => {
        return (
          conceptName.toLowerCase().indexOf(typedConcept.value.toLowerCase()) >=
          0
        );
      }
    );
    if (conceptsNames.length) {
      filteredConceptsByTopics.push({
        topicName: element.topicName,
        topicFullData: element.topicFullData,
        conceptsNames: conceptsNames,
        conceptsFullData: element.conceptsFullData
      });
    }
  });
  return filteredConceptsByTopics;
});

const message = computed<string>(() => {
  if (props.isLoading) {
    return "Loading...";
  }
  if (!props.xmlParsedSuccessfully) {
    return "Curriculum file is invalid";
  }
  if (!conceptsByTopicsFromXml.value.length) {
    return "Curriculum file has no topics or concepts";
  }
  if (sectionHaveTagsButDoesntHaveTagsFromSelectedCurriculum.value) {
    return `This section doesn't have tags from selected curriculum, but have tags from another curriculum.`;
  }
  return "";
});
const showTags = computed<boolean>(() => {
  return (
    !props.isLoading &&
    props.xmlParsedSuccessfully &&
    conceptsByTopicsFromXml.value.length > 0 &&
    !!sectionTagsWithNames.value &&
    (sectionTagsWithNames.value.length > 0 ||
      (sectionTagsWithNames.value.length === 0 &&
        uiGeneralStore.isEditModeEnabled))
  );
});
const showConceptsSelector = computed<boolean>(() => {
  return (
    !props.isLoading &&
    props.xmlParsedSuccessfully &&
    !!conceptsByTopicsFromXml.value.length &&
    props.canEditContent
  );
});

const getConceptByName = (conceptName: string): ConceptJson | undefined => {
  return props.conceptsFromXml.find((concept: ConceptJson) => {
    return concept.name === conceptName;
  });
};
const addConceptToSection = (concept: ConceptJson): void => {
  const payload = {
    concept_guid: concept.guid,
    section_id: props.section.id
  };
  contentStore.addConceptToSection(payload, props.section.product_id);
};
const removeConceptFromSection = (conceptId: number): void => {
  contentStore.removeConceptFromSection(conceptId, props.section.product_id);
};
const processSelectedConcept = (conceptName: string): void => {
  const selectedConcept = getConceptByName(conceptName);
  const selectedSectionConceptsGuids = props.section.concepts.map(
    (concept: SectionConcepts) => concept.concept_guid
  );
  if (
    selectedConcept &&
    !selectedSectionConceptsGuids.includes(selectedConcept.guid)
  ) {
    addConceptToSection(selectedConcept);
  }
};
</script>
