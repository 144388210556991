<template>
  <div
    :class="[
      'main-layout',
      {
        'right-sidebar-padding': uiGeneralStore.isRightSidebarOpen,
        'left-sidebar-padding': uiGeneralStore.isSidebarOpen,
        'has-subheader': hasSubHeader,
        'has-2-subheaders': has2SubHeaders
      }
    ]"
    data-testing="main-container"
  >
    <Header />
    <Sidebar />

    <router-view />

    <AttachFile v-if="uiFilesStore.isAttachFileModalOpen" />
    <AddMetadata v-if="uiContentTemplatesStore.isAddMetadataModalOpen" />
    <AddProductImage v-if="uiProductsStore.isProductImageModalVisible" />

    <teleport to="body">
      <!-- Content sidebar-->
      <transition name="sidebar-slide">
        <ContentSidebar v-if="uiContentStore.sectionToDisplay" />
      </transition>
      <!-- File sidebar-->
      <transition name="sidebar-slide">
        <FileSidebar v-if="uiFilesStore.fileForDisplay" />
      </transition>
      <!-- Create content sidebar-->
      <transition name="sidebar-slide">
        <CreateSection v-if="uiContentStore.newSectionDetails" />
      </transition>

      <ToggleEditMode />
    </teleport>
  </div>
</template>
<script lang="ts" setup>
import Sidebar from "@/components/sidebar/Sidebar.vue";
import Header from "@/components/header/Header.vue";
import ContentSidebar from "@/components/right-sidebars/content-sidebar/ContentSidebar.vue";
import CreateSection from "@/components/right-sidebars/CreateSection.vue";
import FileSidebar from "@/components/right-sidebars/FileSB.vue";
import AttachFile from "@/components/popups/attachments/attach-file/AttachFile.vue";
import AddMetadata from "@/components/popups/AddMetadata.vue";
import AddProductImage from "@/components/popups/AddProductImage.vue";
import ToggleEditMode from "@/components/header/ToggleEditMode.vue";
import { useUIProductsStore } from "@/stores/ui-products.store";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useUIContentTemplatesStore } from "@/stores/ui-content-template.store";
import { useUIGeneralStore } from "@/stores/ui-general.store";
import { useRoute } from "vue-router";
import { computed } from "vue";
import { useUIContentStore } from "@/stores/ui-content.store";

const uiProductsStore = useUIProductsStore();
const uiFilesStore = useUIFilesStore();
const uiContentTemplatesStore = useUIContentTemplatesStore();
const uiGeneralStore = useUIGeneralStore();
const uiContentStore = useUIContentStore();
const route = useRoute();

const hasSubheader = [
  "product",
  "adaptive-learning-systems-list",
  "adaptive-learning-system",
  "progress",
  "productTasks",
  "planning",
  "mcq",
  "qti-preview",
  "qti-editor",
  "html-editor",
  "html-preview",
  "question-editor",
  "question-preview",
  "create-batch"
];
const has2Subheaders = [
  "product-permissions",
  "feature-management",
  "content-templates",
  "assets",
  "content"
];
const hasSubHeader = computed<boolean>(() => {
  return hasSubheader.includes(route.name as string);
});
const has2SubHeaders = computed<boolean>(() => {
  return has2Subheaders.includes(route.name as string);
});
</script>
<style lang="scss" scoped>
.main-layout {
  position: relative;
  // We need padding because we use position: fixed for the main header
  padding-top: $header-height;
  padding-left: $sidebar-width-sm;
  min-height: 100vh;
  transition: padding-right 0.3s ease-out, padding-left 0.3s ease-out;
  :deep(.preloader-container.full-screen) {
    top: $header-height;
    left: $sidebar-width-sm;
    width: calc(100% - #{$sidebar-width-sm});
    height: calc(100vh - #{$header-height});
  }
  &.has-subheader {
    padding-top: $product-page-header-height;
  }
  &.has-2-subheaders {
    padding-top: calc(
      #{$product-page-header-height} + #{$bottom-subheader-height}
    );
  }
  &.left-sidebar-padding {
    padding-left: $sidebar-width;
    :deep(.preloader-container.full-screen) {
      left: $sidebar-width;
      width: calc(100% - #{$sidebar-width});
    }
    @media (max-width: $breakpoint-md) {
      padding-left: $sidebar-width-md;
      :deep(.preloader-container.full-screen) {
        left: $sidebar-width-md;
        width: calc(100% - #{$sidebar-width-md});
      }
    }
  }
}

#app .full-screen-modal,
.dialog.modal.is-active {
  z-index: 902; // Must be greater than the z-index of the sidebar
}
</style>
