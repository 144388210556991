<template>
  <div class="margin-b-standard">
    <RightSidebarTitle :title="'Media Content'" />
    <div v-if="filesToDisplay.length > 0">
      <FilesList
        :filesToDisplay="filesToDisplay"
        :section="section"
        :is-attachments-table="false"
      />
      <button
        class="btn-3"
        type="button"
        @click="$emit('previewAttachment', filesToDisplay[0].location)"
      >
        <SVGIcon :path="mdiPresentationPlay" />
        Preview
      </button>
    </div>
    <div v-if="isStartMessageVisible" class="start-message">
      <p
        >Add media content in one of the supported formats (upload/external
        link)</p
      >
      <button class="btn-3" type="button" @click="openFileModal">
        <SVGIcon :path="mdiPlus" />
        Add Content
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, PropType, ref, watch } from "vue";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import FilesList from "@/components/right-sidebars/content-sidebar/authoring-tool/FilesList.vue";
import { LibraryFile } from "@/models/files.model";
import { ContentStructureSection } from "@/models/content-structure.model";
import { SectionFile } from "@/models/product-section.model";
import { mdiPlus, mdiPresentationPlay } from "@mdi/js";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useFilesStore } from "@/stores/files.store";

type FileToDisplay = SectionFile | LibraryFile | UnavailableFile;
interface UnavailableFile {
  name: string;
  location: string;
  is_external: boolean;
}

const uiFilesStore = useUIFilesStore();
const filesStore = useFilesStore();

const props = defineProps({
  section: {
    type: Object as PropType<ContentStructureSection>,
    required: true
  }
});

const isFilesToDisplayLoading = ref<boolean>(true);
const filesToDisplay = ref<FileToDisplay[]>([]);
const getFileById = <T extends SectionFile | LibraryFile>(
  filesArray: T[]
): T | undefined => {
  // If a file was attached after the media content logic changes
  return filesArray.find((file: T): boolean => {
    return (
      file.id === props.section.authored_content.externalContent.attachmentId
    );
  });
};
const getFileByLocation = <T extends SectionFile | LibraryFile>(
  filesArray: T[]
): T | undefined => {
  // If a file was attached before the media content logic changes
  return filesArray.find((file: T): boolean => {
    return file.location === props.section.authored_content.externalContent;
  });
};
const getFilesToDisplay = (): void => {
  filesToDisplay.value = [];
  isFilesToDisplayLoading.value = true;

  const externalContent = props.section.authored_content?.externalContent;
  if (!externalContent) {
    isFilesToDisplayLoading.value = false;
    return;
  }

  const attachmentIdExists = externalContent.hasOwnProperty("attachmentId");

  let currentFile: LibraryFile | SectionFile | undefined;

  // This is needed due to a change in the logic of setting a file as media content.
  // First, we look for whether our file is in the enabled files in a section,
  // so that we can detach the file from the section using the file drop-down menu
  // in the media content section
  if (attachmentIdExists) {
    currentFile = getFileById(props.section.enabled_files);
    // If a file is not in the enabled files of a section,
    // we look for it in the files library to get the necessary information about the file
    if (!currentFile) {
      currentFile = filesStore.files
        ? getFileById(filesStore.files)
        : undefined;
    }
  } else {
    // If the file was attached before the logic changes
    currentFile = getFileByLocation(props.section.enabled_files);
    // If a file is not in the enabled files of a section,
    // we look for it in the files library to get the necessary information about the file
    if (!currentFile) {
      currentFile = filesStore.files
        ? getFileByLocation(filesStore.files)
        : undefined;
    }
  }

  if (currentFile) {
    filesToDisplay.value.push(currentFile);
  } else {
    // Hide files deleted from the library
    if (attachmentIdExists) {
      isFilesToDisplayLoading.value = false;
      return;
    }
    // If the file is not in our file library and was attached before the media content logic changes
    filesToDisplay.value.push({
      name: props.section.authored_content.externalContent,
      location: props.section.authored_content.externalContent,
      is_external: true
    });
  }
  isFilesToDisplayLoading.value = false;
};
onMounted(() => {
  getFilesToDisplay();
});
watch(
  () => props.section,
  () => {
    getFilesToDisplay();
  }
);

const isStartMessageVisible = computed<boolean>(() => {
  return !isFilesToDisplayLoading.value && !filesToDisplay.value.length;
});
const openExternalContent = (): void => {
  window.open(filesToDisplay.value[0].location, "_blank");
};
const openFileModal = (): void => {
  uiFilesStore.openAttachFileModal({
    section: props.section,
    shouldBeSetAsMediaContent: true
  });
};
</script>
<style scoped lang="scss">
.start-message {
  p {
    padding-bottom: 0;
    font-size: 0.875rem;
  }
}
</style>
