<template>
  <teleport to="body">
    <b-modal
      :model-value="isModalOpen"
      :has-modal-card="true"
      :trap-focus="true"
      aria-role="dialog"
      :aria-modal="true"
      :can-cancel="true"
      :width="width"
      class="full-screen-modal"
      @cancel="$emit('closePopup')"
    >
      <div
        :class="['modal-card', ...modalCardClasses]"
        :data-testing="dataTestingModalCard"
      >
        <div class="modal-card-header">
          <slot name="header"></slot>
          <button
            type="button"
            class="close-btn btn-3"
            title="close"
            aria-label="close"
            @click="$emit('closePopup')"
          >
            <SVGIcon :path="mdiClose" type="is-white" :size="24" />
          </button>
        </div>
        <div class="modal-card-content">
          <slot name="body"></slot>
        </div>
        <div class="modal-card-footer" v-if="$slots.footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </b-modal>
  </teleport>
</template>
<script setup lang="ts">
import { PropType, ref } from "vue";
import { mdiClose } from "@mdi/js";

const isModalOpen = ref<boolean>(true);

defineEmits(["closePopup"]);
defineProps({
  width: {
    required: false,
    type: Number,
    default: 960
  },
  modalCardClasses: {
    required: false,
    type: Array as PropType<string[]>,
    default: () => []
  },
  dataTestingModalCard: {
    required: false,
    type: String,
    default: ""
  }
});
</script>
<style scoped lang="scss">
.modal-card {
  border-radius: $border-radius;
  min-width: 23rem;
  max-width: 100%;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    padding: 1rem;
    color: white;
    background-color: $primary-color;
    .close-btn:only-child {
      margin-left: auto;
    }
  }
  &-content {
    padding: 1rem;
    min-height: 10rem;
    background-color: white;
    &:only-child {
      border-radius: $border-radius;
    }
    &:first-child {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
    &:last-child {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    padding: 0 1rem 1rem;
    background-color: white;
    :deep(button) {
      flex-grow: 1;
      flex-shrink: 1;
      width: 45%;
      &:only-child {
        width: 100%;
      }
    }
  }
}
</style>
<style lang="scss">
body > .modal .modal-close {
  display: none;
}
</style>
