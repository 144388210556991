<template>
  <Popup @closePopup="$emit('closeTransitionConfirmation')">
    <template v-slot:header>
      <h3>
        Move
        <em>{{ section.name }}</em> to
        <em>{{ pendingTransition.name }}</em>
      </h3>
    </template>
    <template v-slot:body>
      <form
        id="transition-confirmation"
        @submit.prevent="$emit('confirmTransition')"
      >
        <RightSidebarTitle :title="'Enter a message:'">
          <small class="pl-2">(optional)</small>
        </RightSidebarTitle>
        <textarea
          :value="props.modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
        ></textarea>
      </form>
    </template>
    <template v-slot:footer>
      <button
        class="btn-2"
        @click="$emit('closeTransitionConfirmation')"
        type="button"
        :disabled="isTransitionSubmitting"
      >
        Cancel
      </button>
      <button
        class="btn-1"
        data-testing="confirm-transition"
        type="submit"
        form="transition-confirmation"
        :disabled="isTransitionSubmitting"
      >
        Confirm
      </button>
    </template>
  </Popup>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import { ContentStructureSection } from "@/models/content-structure.model";
import { PendingTransition } from "@/models/transition-state.model";
import Popup from "@/components/Popup.vue";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";

const props = defineProps({
  section: {
    required: true,
    type: Object as PropType<ContentStructureSection>
  },
  pendingTransition: {
    required: true,
    type: Object as PropType<PendingTransition>
  },
  modelValue: {
    required: true,
    type: String
  },
  isTransitionSubmitting: {
    type: Boolean,
    required: true
  }
});
defineEmits([
  "confirmTransition",
  "closeTransitionConfirmation",
  "update:modelValue"
]);
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
  height: 10rem;
}
</style>
